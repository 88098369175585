<template>
  <div class="modal fade" id="returnAccessCode" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Akses Kode</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column align-items-center gap-2">
            <img src="@/assets/icons/lock-icon.svg" alt="">
            <span class="font-14 mb-2">
                Masukkan akses kode untuk membuka menu retur
            </span>
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=" "
              :num-inputs="6"
              :input-type="'password'"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
            />
            <div style="height: 18px">
                <span class="color-red font-12" v-if="error">Kode yang anda masukkan salah</span>
            </div>
        </div>
      
      </div>
    </div>
  </div>
</div>
</template>
<script>
    import {mapActions} from 'vuex'

    export default {
       data() {
        return {
            code: '',
            error: false
        }
       },
       methods: {
        ...mapActions('returns', ['verifyAccessCode']),
        handleOnChange (code) {
            this.code = code
        },
        async handleOnComplete () {
            try {
                const payload = {
                    data: {
                        code: this.code
                    }
                }
                const response =  await this.verifyAccessCode(payload)
                if ( response.data.status_code === 200) {
                    this.$cookies.set('return_access_token' , 'sadjhbadsjh213v1231231', 120)
                    this.error = false
                    this.$emit('onComplete', true)
                }
            } catch (error) {
                this.error = true
                this.code = '' 
                this.$refs.otpInput.clearInput();

            }
        }
       },
       mounted () {
        const myModalEl = document.getElementById('returnAccessCode')
        myModalEl.addEventListener('hidden.bs.modal', () => {
            if(!this.$cookies.get('return_access_token')) {
                this.$emit('onComplete', false)
            }
        })
       }
    };
</script>
<style lang="scss">
    .otp-input {
        width: 37px;
        height: 37px;
        padding: 3px;
        margin-right: 8px;
        font-size: 20px;
        font-family: Nunito;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        text-align: center;
        &.error {
            border: 1px solid red !important;
        }
    }
    .otp-input:focus {
        background: #e5f8ef;
        border: 1.5px solid $primary;
        outline-color: $primary;
    }
    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    </style>