<template>
    <div class="page-wrapper d-flex flex-column" :class="{'onSidebarOpen' : sidebarVisible}">
      <div class="search-element">
        <el-form :inline="true" class="d-flex align-items-center gap-3" @submit.native.prevent="changeKeyword">
          <el-input  v-model="orderKeyword" :debounce="2000" class="flex-grow-1" placeholder="Cari Berdasarkan No Invoice" clearable>
            <img slot="prefix" class="icon-search" src="@/assets/icons/icon-search.svg" alt="Icon Search">
          </el-input>
          <button class="btn btn-primary font-14 font-nunito font-700 text-white my-auto py-2 px-4">Cari</button>
        </el-form>
      </div>
      <div class="bg-white p-3 return-product-container">
        <ReturnProductList />
      </div>
        <ReturnAccessCodePopup @onComplete="checkAccessCode" />
    </div>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import {Modal} from 'bootstrap'
  import ReturnAccessCodePopup from "@/components/return/popup/ReturnAccessCodePopup.vue";
  import ReturnProductList from "@/components/return/ReturnProductList.vue";
  import ihsMixins from '@/mixins/ihs.mixins';
  
  export default {
    mixins: [ihsMixins],
    components: { ReturnAccessCodePopup, ReturnProductList },
    data() {
      return {
        accessCodeModal: '',
        orderKeyword: '',
      }
    },
    computed: {
        ...mapState("home", ["sidebarVisible"]),
        keywordQuery() {
          return this.$route.query.keyword;
        }
    },
    methods: {
      ...mapActions("returns", ["searchReturnOrder"]),
      checkAccessCode(isCompleted) {
        if (isCompleted) {
          this.accessCodeModal.hide()
        } else {
          this.$router.go(-1)
        }
      },
      checkKeyword() {
        if (this.keywordQuery) {
          this.orderKeyword = this.keywordQuery;
          this.searchReturn()
        }
      },
      changeKeyword() {
          if (this.$router.currentRoute.query.keyword !== this.orderKeyword) {
            this.setToQuery({ keyword: this.orderKeyword });
          }
      },
      async searchReturn() {
        try {
          const payload = {
            params : {
              search: this.orderKeyword
            }
          }
          await this.searchReturnOrder(payload)

        } catch (error) {
            this.$message({
                showClose: true,
                customClass: "error-message",
                message: error.response.data.message,
                type: "error"
              });
        }
      }
    },
    mounted () {
      this.accessCodeModal = new Modal(document.getElementById('returnAccessCode'), {})
      if(!this.$cookies.get('return_access_token')) {
        this.accessCodeModal.show()
      }
      this.checkKeyword()
    }
  }
</script>
<style lang="scss" scoped>
  
  .page-wrapper {
    padding: 24px 24px 0 24px;
    background: #F5F5F5;
    transition: margin 400ms ease-in-out;
    overflow-x: hidden;
    position: relative;
    &.onSidebarOpen {
      margin-left: 78px;
    }
  }

  .return-product-container {
      margin-top: 20px;
      border-radius: 8px;
      padding: 20px;
  }
</style>